import React, { useEffect  } from 'react';

const AdsComponent = (props) => {
    const { dataAdSlot } = props;  



    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {

        }

    },[]);



    return (
        <>
        <div
                className='mx-auto my-1.5 max-w-screen-lg '
            >
            <ins className="adsbygoogle mt-10 w-72 h-32 sm:h-[90px] sm:w-[450px] md:w-[700px]  lg:w-[1000px]"
                style={{ display: "block"}}
                data-ad-client="ca-pub-0722903873683833"
                data-ad-slot={dataAdSlot}
                >
            </ins>




        </div>
        </>
    );
};

export default AdsComponent;